<template>
  <div class="p-fluid">

    <div class="p-mb-3">
      <Splitter>
        <SplitterPanel>
          <div class="p-p-4">
            <span style="font-size: 24px; font-weight: bold;">Foodies</span>

            <ul>
              <li>Total: {{ dashboard?.foodies_count.total }}</li>
              <li>Instagram validado: {{ dashboard?.foodies_count.ig_validated }}</li>
            </ul>

            <div v-if="dashboard?.foodies.latest.length > 0" style="padding-top: 15px;">
              <span style="font-size: 16px; font-weight: bold;">Últimos foodies</span>

<!--              <ul>-->
<!--                <li v-for="(foodie) in dashboard?.foodies.latest" :key="foodie.id">-->
<!--                <span v-tooltip.top="`ID: ${foodie.id}`">-->
<!--                  {{foodie.name}} ({{foodie.email}}) - {{dateToLocalTime(foodie.created_at)}}-->
<!--                </span>-->
<!--                </li>-->
<!--              </ul>-->

              <DataTable :value="foodies" responsiveLayout="scroll">
                <Column field="name" header="Nombre"></Column>
                <Column field="email" header="Email"></Column>
                <Column field="created_at" header="Fecha de creación"></Column>
              </DataTable>

            </div>

          </div>
        </SplitterPanel>
        <SplitterPanel>
          <div class="p-p-4">
            <span style="font-size: 24px; font-weight: bold;">Restaurantes</span>

            <ul>
              <li>Total: {{ dashboard?.restaurants_count.total }}</li>
              <li>Instagram validado: {{ dashboard?.restaurants_count.ig_validated }}</li>
            </ul>

            <div v-if="dashboard?.restaurants.latest.length > 0" style="padding-top: 15px;">

              <span style="font-size: 16px; font-weight: bold;">Últimos restaurantes</span>

<!--              <ul>-->
<!--                <li v-for="(restaurant) in dashboard?.restaurants.latest" :key="restaurant.id">-->
<!--                <span v-tooltip.top="`ID: ${restaurant.id}`">-->
<!--                  {{restaurant.name}} ({{restaurant.email}}) - {{dateToLocalTime(restaurant.created_at)}}-->
<!--                </span>-->
<!--                </li>-->
<!--              </ul>-->

              <DataTable :value="restaurants" responsiveLayout="scroll">
                <Column field="name" header="Nombre"></Column>
                <Column field="email" header="Email"></Column>
                <Column field="created_at" header="fecha de creación"></Column>
              </DataTable>

            </div>

          </div>
        </SplitterPanel>
      </Splitter>
    </div>


    <div class="p-mr-3 p-mb-2">
      <div style="display: flex;">
        <Card style="flex: 1 1 0; max-width: 250px;">
          <template #title>
            Colaboraciones
          </template>
          <template #content>
            <ul class="list">
              <li>Total: {{ dashboard?.collaborations_count.total }}</li>
              <li>Activos: {{ dashboard?.collaborations_count.active }}</li>
              <li>Cancelados: {{ dashboard?.collaborations_count.canceled }}</li>
              <li>Finalizados: {{ dashboard?.collaborations_count.closed }}</li>
            </ul>
          </template>
        </Card>
        <Card style="flex: 1 1 0;">
          <template #title>
            Latest logins
          </template>
          <template #content>
            <DataTable :value="dashboard?.users?.latest_login" responsiveLayout="scroll">
              <Column field="name" header="Nombre"></Column>
              <Column field="email" header="Email"></Column>
              <Column field="ig_username" header="Ig username"></Column>
              <Column field="type_name" header="Tipo"></Column>
              <Column field="last_login_at" header="last Login"></Column>
            </DataTable>
          </template>
        </Card>
      </div>
    </div>

  </div>
</template>

<script>

import moment from "moment";

export default {
  data() {
    return {
      dashboard: null,
      listBoxTest: [],
      lastLogin: [],
      foodies: [],
      restaurants: []
    }
  },
  mounted() {
    this.getDashboard()
  },
  methods: {
    getDashboard() {
      return this.$repository.dashboard.index().then(({data}) => {
        this.dashboard = data;
        this.lastLogin = data?.users?.latest_login.filter((value) => value.last_login_at = this.dateToLocalTime(value.last_login_at))
        this.foodies = data?.foodies?.latest.filter((value) => value.created_at = this.dateToLocalTime(value.created_at))
        this.restaurants = data?.restaurants?.latest.filter((value) => value.created_at = this.dateToLocalTime(value.created_at))
      })
    },
    dateToLocalTime(date) {
      if (date === null) {
        return null;
      }

      return moment(date).local().format('YYYY-MM-DD HH:mm:ss')
    }
  }
}
</script>

<style lang="scss" scoped>
.card-a {
  width: 15rem;
  height: 14rem;
}

@media (min-width: 360px) and (max-width: 768px) {
  .card-a {
    width: 100%;
    height: 12rem;
  }
}

</style>
